<template>
  <section class="enterprise-setup h-100 p-3 pb-5">
    <div class="container mb-2">
      <div class="d-flex align-items-center justify-content-center h-100 mt-3 mb-3 pt-2 pb-2">
        <div class="d-flex align-items-center me-auto">
          <div class="flex-shrink-0">
            <img
              src="@/assets/images/logo.png"
              class="logo"
              alt="Relatus"
            >
          </div>
          <div class="flex-grow-1 ms-3">
            <h2><span class="font-normal">Your</span> Relatus Profile</h2>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <r-button
            variant="primary"
            round
            outline
            class="ml-auto"
          >
            Save Changes
          </r-button>
        </div>
      </div>
    </div>

    <div class="container container-card h-100 p-2">
      <div class="row">
        <div class="col-12 text-start">
          <div class="d-flex align-items-center border-light-gray ps-4 pe-4" style="border: 1px solid rgba(3, 29, 90, 0.2);box-sizing: border-box;border-radius: 12px;margin: 20px;">
            <div class="flex-shrink-0">
              <img
                src="@/assets/images/enterprise_setup_header.svg"
                alt="Enterprise Setup"
                style="max-height: 70px;"
              >
            </div>
            <div class="flex-grow-1 ms-3">
              <h5 class="text align-self-center me-auto fw-light" style="color: #323232;">
                Choose a Network Source and Connect
              </h5>
              <p class="text align-self-center text-start" style="color: #999799;">
                The more sources you connect to, the better we can match you to the RIGHT person.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container h-100 mt-5 p-0" style="background-color: #FFFFFF;border: 1px solid rgba(0, 0, 0, 0.1);box-sizing: border-box;box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);border-radius: 10px;padding: 20px!important;">
      <div class="row align-items-center">
        <span class="text-sm font-bold uppercase mb-3">Connect To</span>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_linkedin.png"
              alt="LinkedIn"
              class="social-platform"
            >
            <h5>LinkedIn</h5>
            <r-button
              variant="primary"
              round
              outline
              class="ml-auto mt-3"
              style="width: 100%;padding: 3px;"
            >
              Connect
            </r-button>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_google.png"
              alt="Google"
              class="social-platform"
            >
            <h5>Google</h5>
            <r-button
              variant="primary"
              round
              outline
              class="ml-auto mt-3"
              style="width: 100%;padding: 3px;"
            >
              Connect
            </r-button>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_facebook.png"
              alt="Facebook"
              class="social-platform"
            >
            <h5>Facebook</h5>
            <r-button
              variant="primary"
              round
              outline
              class="ml-auto mt-3"
              style="width: 100%;padding: 3px;"
            >
              Connect
            </r-button>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_twitter.png"
              alt="Twitter"
              class="social-platform"
            >
            <h5>Twitter</h5>
            <r-button
              variant="primary"
              round
              outline
              class="ml-auto mt-3"
              style="width: 100%;padding: 3px;"
            >
              Connect
            </r-button>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <span class="text-sm font-bold uppercase mb-3 mr-3">Coming Soon</span>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_outlook.png"
              alt="Outlook"
              class="social-platform"
            >
            <h5>Outlook</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_imessages.png"
              alt="iMessages"
              class="social-platform"
            >
            <h5>iMessages</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_apple.png"
              alt="Apple Contacts"
              class="social-platform"
            >
            <h5>Apple Contacts</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_whatsapp.png"
              alt="WhatsAppWhatsAppWhatsApp"
              class="social-platform"
            >
            <h5>WhatsApp</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_clubhouse.png"
              alt="Clubhouse"
              class="social-platform"
            >
            <h5>Clubhouse</h5>
          </div>
        </div><div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_slack.png"
              alt="Slack"
              class="social-platform"
            >
            <h5>Slack</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_instagram.png"
              alt="Instagram"
              class="social-platform"
            >
            <h5>Instagram</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_salesforce.png"
              alt="SalesForce"
              class="social-platform"
            >
            <h5>SalesForce</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_tiktok.png"
              alt="TikTok"
              class="social-platform"
            >
            <h5>TikTok</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_affinity.png"
              alt="Affinity"
              class="social-platform"
            >
            <h5>Affinity</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="container-card">
            <img
              src="@/assets/images/social/ic_hubspot.png"
              alt="Hubspot"
              class="social-platform"
            >
            <h5>Hubspot</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ConnectNetwork'
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
.enterprise-setup {
  background-color: #EDEDED;
}
.enterprise-setup h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.enterprise-setup h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #142328;
  letter-spacing: 1px;
}
.enterprise-setup h5 {
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #1E1E1E;
  margin-top: 20px;
  max-height: 30px;
}
.enterprise-setup .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
.logo {
  max-height: 33px!important;
}
.enterprise-setup img {
  height: 125px;
}
.container-card {
  padding: 20px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
a {
  text-decoration: none;
}
button {
  background-color: #FFFFFF!important;
  border: 1px solid #83C03B;
  box-sizing: border-box;
  border-radius: 30px;
}
button:hover {
  color: #83C03B!important;
  border: 1px solid #83C03B!important;
}
img.social-platform {
  max-height: 50px;
}
</style>
